import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Typography, Container } from "@mui/material";

export const Contact = () => {
  return (
    <>
      <Header />
      <br />
      <br />
      <Container>
        <Typography variant="h3" textAlign="center">
          <b>Contact Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          <b>Corporate Office</b>
        </Typography>
        <br />
        <Typography>
          Plot C, 9, Rd Number 18, Durga Bhawani Nagar, Film Nagar, Hyderabad,
          Telangana 500096
        </Typography>
        <br />
        <br />
        <Typography>
          <b>Regional Office</b>
        </Typography>
        <br />
        <Typography>
          3rd Floor, B&M Business Centre,Malepally Road, Mavelipuram, Kakkanad,
          Kochi, Kerala- 682030. India
        </Typography>
        <br />
        <br />
        <Typography>
          <b>UK Office</b>
        </Typography>
        <br />
        <Typography>40, Spring Road, Ipswich, IP42RS</Typography>
        <Typography>
          Apartment 9, Monea Hall, Conisbrough Keep, Converty, England, CV15PY
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
