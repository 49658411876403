import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Box, Container, Typography, Grid } from "@mui/material";

export const AboutChairman = () => {
  return (
    <>
      <Header />
      <br />
      <br />
      <Container>
        <Typography variant="h4" textAlign="center">
          <b>Meet our Chairman and Managing Director</b>
        </Typography>
        <br />
        <br />
        <br />
        <Grid container spacing={4}>
          <Grid item md={4}>
            <img src="/images/MujeebRahman.png" alt="Image" />
            <br />
            <br />
            <Typography variant="h5" textAlign="center">
              Mr. Mujeeb Rahman
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography>
              Mr. Mujeeb Rahman, an enthusiastic film script writer, film
              producer, actor and an entrainment lover As the Chairman of ZCo
              media Private Limited and ZCo OTT, he plays a critical role in
              driving the strategic direction and success of the organization.
              His leadership sets the tone for the company culture, and his
              vision guides the company's decisions and actions.
            </Typography>
            <br />
            <Typography>
              He is responsible for overseeing the operations of the company,
              ensuring that it runs smoothly, efficiently, and profitably. He
              works closely with the executive team and other key stakeholders
              to develop and execute long-term strategies that align with the
              company's goals and values. He also monitors the performance of
              the company, identifying areas for improvement and taking decisive
              action to address any challenges.
            </Typography>
            <br />
            <Typography>
              As the Chairman, he is the face of the company, representing it to
              the public, investors, and other stakeholders. He is responsible
              for building and maintaining relationships with key partners,
              including content creators, distributors, advertisers, and
              investors. You also have a crucial role in shaping the company's
              public image and reputation, working to promote its brand, values,
              and mission.
            </Typography>
            <br />
            <Typography>
              Mr. Mujeeb Rahman’s leadership style sets the tone for the company
              culture, and he play a key role in attracting, retaining, and
              developing talent. He fosters an environment that promotes
              creativity, collaboration, and innovation, empowering our team to
              take risks and push the boundaries of what is possible in the
              media and entertainment industry.
            </Typography>
            <br />
            <Typography>
              As the Chairman, you are also responsible for ensuring that the
              company operates in a socially responsible and sustainable manner.
              You promote diversity, equity, and inclusion within the company,
              and work to ensure that your platform reflects the diverse
              perspectives and experiences of your audience. You also take steps
              to minimize the environmental impact of the company's operations,
              promoting sustainability and responsible resource management.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
