import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography } from "@mui/material";

export const AboutTeam = () => {
  return (
    <>
      <Header />
      <br />
      <br />
      <Container>
        <Typography variant="h3" textAlign="center">
          <b>The Team</b>
        </Typography>
        <br />
        <Typography>
          At the heart of our company's success is a team of talented and
          creative individuals who bring their unique skills and perspectives to
          the table. From writers and directors to producers and designers, our
          team works tirelessly to bring the best possible content to our
          viewers.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
