import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography } from "@mui/material";

export const AboutUs = () => {
  return (
    <>
      <Header />
      <Container>
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          As an OTT and media company, we play a crucial role in shaping the
          entertainment landscape for millions of people around the world. We
          provide a diverse range of content that caters to the tastes and
          preferences of audiences across different age groups, cultures, and
          geographies.
        </Typography>
        <br />
        <Typography>
          Our platform has revolutionized the way people consume entertainment,
          giving them the freedom to watch their favorite Vintage Movies, Latest
          Movies, Web Series or TV shows and Documentaries at any time and from
          anywhere. With the rise of mobile devices and internet connectivity,
          our company has become an integral part of people's daily lives,
          providing a constant source of entertainment and information.
        </Typography>
        <br />
        <Typography>
          Our company's commitment to innovation and excellence has earned it a
          reputation as a trailblazer in the media industry. Our commitment to
          innovation, excellence, and social responsibility has made you a
          trailblazer in the industry, setting the standard for others to
          follow.
        </Typography>
        <br />
        <Typography>
          We have embraced new technologies and business models, constantly
          pushing the boundaries of what is possible in the world of
          entertainment. We have also built strong relationships with industry
          partners including production houses, media companies, film studios,
          distributors, and content creators, to ensure a steady supply of
          high-quality content for your platform. Our platform has become a
          force for good, promoting diversity, inclusion, and social justice
          through the content you produce and the stories you tell. In
          conclusion, our OTT and media company is a vital player in the
          entertainment industry, providing a diverse range of content to
          millions of people around the world.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
