import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

export const Profile = () => {
  return (
    <>
      <Header />
      Profile Goes Here
      <Footer />
    </>
  );
};
