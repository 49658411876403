import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

export const FAQ = () => {
  return (
    <>
      <Header /> FAQ <Footer />
    </>
  );
};
