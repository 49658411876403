import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Container, Typography, IconButton } from "@mui/material";
import {
  Twitter,
  Facebook,
  Instagram,
  ArrowDropDown,
} from "@mui/icons-material";

export const Footer = () => {
  const [showLink, setShowLink] = useState(false);

  return (
    <footer>
      <Container maxWidth="xl" sx={{ mb: 5 }}>
        <Grid container spacing={5}>
          <Grid item md={6}>
            <Link to="/">
              <img
                style={{ height: "2.5rem", width: "auto" }}
                src="/images/logo.png"
                alt="Logo"
              />
            </Link>
            <br />
            <br />
            <br />
            <Typography>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore
              laudantium quae, corporis laboriosam alias, voluptatibus tempora
              non nam, corrupti veritatis magni repudiandae nemo tenetur
              possimus eius dolorum omnis impedit sed?
            </Typography>
            <br />
            <br />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>
                <b>Follow us on :</b>
              </Typography>
              &nbsp; &nbsp;
              <IconButton onClick={() => alert("Clicked")} color="error">
                <Facebook />
              </IconButton>
              &nbsp; &nbsp;
              <IconButton onClick={() => alert("Clicked")} color="error">
                <Instagram />
              </IconButton>
              &nbsp; &nbsp;
              <IconButton onClick={() => alert("Clicked")} color="error">
                <Twitter />
              </IconButton>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Typography>
              <b>Company</b>
            </Typography>
            <br />
            <br />
            <Box display="flex" alignItems="center">
              <Link to="/about">
                <Typography>About Us</Typography>
              </Link>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => setShowLink(!showLink)}
              >
                <ArrowDropDown color="white" />
              </IconButton>
            </Box>
            {showLink ? (
              <Box sx={{ mt: 1 }}>
                <Link to="/about-chairman">
                  <Typography>About Chairman</Typography>
                </Link>
                <Link to="/about-team">
                  <Typography>The Team</Typography>
                </Link>
              </Box>
            ) : (
              <></>
            )}
            <Link to="/contact">
              <Typography sx={{ mt: 1, mb: 1 }}>Contact Us</Typography>
            </Link>
            <Link to="/faq">
              <Typography>FAQ</Typography>
            </Link>
          </Grid>
          <Grid item md={3}>
            <Typography>
              <b>Quick Links</b>
            </Typography>
            <br />
            <br />
            <Link to="/">
              <Typography>Home</Typography>
            </Link>
            <Link to="/shows">
              <Typography sx={{ mb: 1, mt: 1 }}>Shows</Typography>
            </Link>
            <Link to="/movies">
              <Typography>Movies</Typography>
            </Link>
            <Link to="/news">
              <Typography sx={{ mb: 1, mt: 1 }}>News</Typography>
            </Link>
            <Link to="/kids">
              <Typography>Kids</Typography>
            </Link>
            <Link to="/music">
              <Typography sx={{ mt: 1 }}>Music</Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <hr />
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Typography>
          <b>Copyright © All Rights Reserved</b>
        </Typography>
      </Box>
    </footer>
  );
};
